
import { defineComponent } from "vue";
import store from "@/store/index";
import { SignInState } from "@/store/workspace/types";
import { signInFlow } from "@/main";

export default defineComponent({
  name: "AuthGuard",
  props: {
    workspace: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      // Constants
      SignInState: SignInState,

      // Sign In
      signedIn: false,
      signInState: SignInState.None,

      // Workspace
      loadedWorkspace: false,
      workspaceState: true,
      findFailed: false,
      accessFailed: false,
    };
  },
  watch: {
    _workspaceId: function (to, from) {
      this.loadedWorkspace = false;
      this.workspaceState = false;
      this.findFailed = false;
      this.accessFailed = false;

      if (to) {
        this.loadWorkspace(to).then(() => {
          this.$emit("valid");
        });
      } else {
        this.$emit("valid");
      }
    },
  },
  computed: {
    _workspaceId: function () {
      return this.$route.params.workspaceId;
    },
  },
  created: function () {
    store.getters["user/signInState"].then(async (res: SignInState) => {
      this.signedIn = true;
      this.signInState = res;
      if (this.signInState == SignInState.Success) {
        if (
          this.workspace &&
          store.getters["workspace/getId"] != this.$route.params.workspaceId
        ) {
          await this.loadWorkspace(this.$route.params.workspaceId as string);
        }
        this.loadedWorkspace = true;
        this.$emit("valid");
      }
    });
  },
  methods: {
    login: function () {
      this.$gapi.getAuthInstance().then((auth) => {
        auth.signIn({ prompt: "select_account" }).then((result: any) => {
          store.commit("user/updateSignInState", signInFlow());
          store.getters["user/signInState"].then((signInState: any) => {
            this.signInState = signInState;
          });
        });
      });
    },
    loadWorkspace: function (workspace: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const start = new Date().getTime();
        console.log("Loading Workspace:", new Date().getTime() - start, "ms");
        store
          .dispatch("workspace/loadWorkspace", workspace)
          .then((result) => {
            console.log(
              "Loaded Workspace:",
              new Date().getTime() - start,
              "ms"
            );
            this.loadedWorkspace = true;
            resolve();
            if (!this.$route.params.pageId) {
              if (this.$route.name != "Workspace Root") {
                console.log("routing");
                this.$router.push(`/-/${workspace}`).then(() => {
                  resolve();
                });
              }
            }
          })
          .catch((error) => {
            console.warn(error);
            this.loadedWorkspace = true;
            this.workspaceState = false;
            this.findFailed = true;
            reject();
          });
      });
    },
  },
});
