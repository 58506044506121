<template lang="html">
  <AuthGuard :workspace="false" @valid="handleValid">
    <div
      class="flex items-center justify-center"
      :style="{ height: '100vh', width: '100vw' }"
    >
      <!-- Gradient -->
      <div
        class="fixed top-0 left-0 overflow-hidden fade-in-canvas flex justify-center"
        :style="{ height: '100vh', width: '100vw' }"
        :class="{ 'hide-canvas': !sketchReady }"
        ref="sketch"
      ></div>

      <!-- Step 0 -->
      <div
        class="relative rise-animation bg-paper mx-auto fade-in-canvas white-shadow"
        v-if="step == 0"
      >
        <h1 class="text-center">Welcome to Velocity</h1>
        <div class="flex justify-center">
          <div
            @click="step = 2"
            class="btn-landing-negative p-cell rounded-mention my-12 inline-block cursor-pointer"
          >
            Get Started
          </div>
        </div>
      </div>

      <!-- Step 1 -->
      <div class="relative rise-animation white-shadow" v-if="step == 1">
        <h2 class="text-center">Create a new Workspace</h2>
        <p class="text-center">
          Workspaces are document environments for your team to write.
        </p>
        <section class="flex flex-col px-34 rounded-mention py-20 gap-12">
          <div class="mention">Workspace Name:</div>
          <input
            type="text"
            class="settings-input"
            v-model="workspaceName"
            @keypress.enter="step = 2"
          />
        </section>
        <div class="flex justify-center">
          <div
            @click="step = 2"
            class="btn-landing p-cell rounded-mention my-12 inline-block cursor-pointer"
          >
            continue
          </div>
        </div>
      </div>

      <!-- Step 2 -->
      <div class="relative rise-animation white-shadow" v-if="step == 2">
        <h2 class="text-center">Command Menu</h2>
        <p class="text-center">Use the context menus to manage your page</p>
        <section class="flex flex-col px-34 rounded-mention py-20 gap-12">
          <div class="font-semibold mention text-center">
            Open the command menu with:
          </div>
          <div class="flex">
            <div class="flex-1 flex flex-col items-center gap-3">
              <div
                class="rounded-mention bg-off-white shadow-base flex items-center justify-center text-title"
                :style="{ width: '72px', height: '72px' }"
              >
                /
              </div>
              <div class="sub text-center">to format the document</div>
            </div>
            <div class="flex-1 flex flex-col items-center gap-3">
              <div
                class="rounded-mention bg-off-white shadow-base flex items-center justify-center text-title"
                :style="{ width: '72px', height: '72px' }"
              >
                =
              </div>
              <div class="sub text-center">to reference pages</div>
            </div>
          </div>
        </section>
        <div class="flex justify-center">
          <div
            @click="step = 3"
            class="btn-landing p-cell rounded-mention my-12 inline-block cursor-pointer"
          >
            continue
          </div>
        </div>
      </div>

      <!-- Step 3 -->
      <div class="relative rise-animation white-shadow" v-if="step == 3">
        <h2 class="text-center">Notes</h2>
        <p class="text-center">Create notes to group and drag text</p>
        <section class="flex flex-col px-34 rounded-mention py-20 gap-12">
          <div class="flex">
            <div class="flex-1 flex flex-col items-center gap-3">
              <div
                class="rounded-mention bg-off-white shadow-base flex items-center justify-center text-title px-12"
                :style="{ height: '72px' }"
              >
                ctrl-shift-g
              </div>
              <div class="sub text-center">to create notes</div>
            </div>
          </div>
        </section>
        <div class="flex justify-center">
          <div
            @click="step = 4"
            class="btn-landing p-cell rounded-mention my-12 inline-block cursor-pointer"
          >
            continue
          </div>
        </div>
      </div>

      <!-- Step 4 -->
      <div class="relative rise-animation white-shadow" v-if="step == 4">
        <h2 class="text-center">Invite your team</h2>
        <p class="text-center">
          Velocity is meant to be used as a team, invite your coworkers to test
          it out
        </p>
        <section class="flex flex-col px-34 rounded-mention py-20 gap-12">
          <div class="mention">Add an email:</div>
          <div class="flex gap-3 settings-input">
            <input
              type="text"
              class="flex-grow p-mention"
              v-model="activeEmail"
              @keypress.enter="addEmail"
            />
            <Icon
              :icon="['fal', 'check-square']"
              class="icon-base clickable p-mention"
              @click="addEmail"
            />
          </div>
          <div class="mention">Team:</div>
          <div
            class="flex flex-wrap gap-3 settings-link items-start justify-start content-start"
            :style="{ 'min-height': '120px', cursor: 'initial' }"
          >
            <div
              class="flex sub gap-3 items-center"
              :key="i"
              v-for="(email, i) in teamEmails"
            >
              <div class="p-sub">
                {{ email }}
              </div>

              <Icon
                :icon="['fal', 'times']"
                class="icon-sm clickable rounded-mention"
                @click="removeEmail(i)"
              />
            </div>
          </div>
        </section>
        <div class="flex justify-center">
          <div
            @click="enterWorkspace"
            class="btn-landing p-cell rounded-mention my-12 inline-block cursor-pointer"
          >
            continue
          </div>
        </div>
      </div>
      <!-- Step 6 -->
      <div class="relative rise-in-and-away white-shadow" v-if="step == 5">
        <h1 class="text-center">Welcome</h1>
      </div>
    </div>
  </AuthGuard>
</template>

<script lang="js">
import { defineComponent, nextTick } from "vue";
import store from "@/store/index";
import p5 from "p5";
import { WorkspaceMeta } from "@/store/workspace/types";
import AuthGuard from "./AuthGuard.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    AuthGuard,
  },
  data() {
    return {
      step: -1,
      sketchReady: false,
      workspaceName: "",
      sketch: null,
      activeEmail: "",
      teamEmails: [],
      subscribeToChangelog: false,
      workspace: "",
    };
  },
  methods: {
    enterWorkspace: function(event) {
      this.step = 5;

      let late = false;
      setTimeout(() => {
        fetch("https://velocity.keryx.workers.dev/login/", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters['user/getAuth'].id_token,
          },
        }).then((response) => {
          console.log(store.getters['user/getActiveUser'])
          Promise.all(this.teamEmails.map((email) => {
            return new Promise((resolve) => {
              store
                .dispatch("workspace/team/sendInvite", email)
                .then((result) => {
                  this.draftInvite(email, result.id)
                  resolve()
                })
            })
          }))
          .then(() => {
            response.json().then((res) => {
                window.open(`-/${res.workspaces[0]}`, "_self")
            })
          })

        })
      }, 3000)
    },
    addEmail: function(email) {
      this.teamEmails.push(this.activeEmail)
      this.activeEmail = "";
    },
    removeEmail: function(index) {
      this.teamEmails.splice(index, 1)
    },
    handleValid: function() {
      this.step = 0;
      setTimeout(() => {
        this.sketch = new p5((s) => {
          let size;
          let t;
          const resolution = 200;
          const scale = .4 / resolution;
            s.setup = () => {
              s.frameRate(1)
              s.createCanvas(window.innerWidth, window.innerHeight);
              s.noStroke();
              s.colorMode('HSL', 360, 100, 100, 1);
              size = s.width / resolution;
              t = 0
            }

            s.draw = () => {
              s.clear();
              for (let x = 0; x < s.width; x+= size) {
                for (let y = 0; y < s.height; y+= size) {
                  const alpha = 1.5 * 255 * s.noise(scale * x, scale * y, scale * 16.8 * t);
                  s.fill(0, 0, 0, alpha - .75 * 255)
                  s.circle(x, y, size / 1.68);
                }
              }
              this.sketchReady = true;
              t = t + 1;
            }

        }, this.$refs['sketch']);
      }, 80)

    }
  },
});
</script>

<style lang="css" scoped>
.white-shadow {
  @apply bg-paper px-34 py-20 rounded-20;
  width: 548px;
  box-shadow: 0 0 50px 62px #fffcf7;
}

.fade-in-canvas {
  opacity: 1;
  transition: opacity 600ms ease;
}

.fade-in-canvas canvas {
  width: 100%;
}

.hide-canvas {
  opacity: 0;
}

@keyframes in-and-away {
  0% {
    opacity: 0;
    top: 20px;
  }
  20% {
    opacity: 1;
    top: 0;
  }
  80% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -20px;
  }
}

.rise-in-and-away {
  @apply relative;
  animation: in-and-away 3000ms forwards;
}
</style>
